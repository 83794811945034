




















































































































































import { defineComponent, reactive, Ref, ref,onMounted } from '@vue/composition-api'
import InputBox from '@/components/InputBox.vue'
import LoadMore from '@/components/LoadMore.vue'
import { Backtop, Col, Empty, Popconfirm, Row, Tag } from 'element-ui'
import { cloneProjectApi, deleteProjectApi, getProjectsApi, getTimezonesApi } from '@/api/project'
import { ProjectStatus } from '@/api/project/model'
import { showError } from '@/utils/common'
import LayoutHeader from '@/components/LayoutHeader.vue'
import { projectTopicOption } from '@/api/project/topic-option'
import { projectIndustryOption } from '@/api/project/industry-option'
import _ from 'lodash'
import ProjectSharedDialog from './components/ProjectSharedDialog.vue'
import { currentTimezone } from '@/utils/time-format'
export default defineComponent({
    components: {
        [Tag.name]: Tag,
        [Row.name]: Row,
        [Col.name]: Col,
        [Popconfirm.name]: Popconfirm,
        [Empty.name]: Empty,
        [Backtop.name]: Backtop,
        InputBox,
        LoadMore,
        LayoutHeader,
        ProjectSharedDialog
    },
    setup(props, { root }) {
        const maskId = ref(-1)
        const listInfo = reactive({
            page: 0,
            size: 20,
            name: '',
            topic: '',
            industry: '',
            loading: false,
            finished: false,
            first: true,
            isSearch: false,
            onLoad: () => {
                listInfo.page++
                getProjects()
            },
            init: () => {
                projectList.value = []
                listInfo.page = 0
                listInfo.finished = false
                listInfo.isSearch = !!listInfo.name || !!listInfo.topic || !!listInfo.industry
                getProjects()
            }
        })


        const onProjectNameInput = _.debounce(listInfo.init, 1000)

        const projectTopicOptions = [
            {
                label: 'All',
                value: ''
            },
            ...projectTopicOption.map((v) => {
                return {
                    label: v,
                    value: v
                }
            })
        ]

        const projectIndustryOptions = [
            {
                label: 'All',
                value: ''
            },
            ...projectIndustryOption.map((v) => {
                return {
                    label: v,
                    value: v
                }
            })
        ]

        const projectList: Ref<any[]> = ref([])

        const getProjects = async () => {
            listInfo.first = false
            listInfo.loading = true
            try {
                const filters: any[] = [[['project_name', 'like', listInfo.name], ['or'], ['owner_name', 'like', listInfo.name]], ['and'], ['is_trashed', false]]
                if (listInfo.topic) {
                    filters.push(['and'], ['topic', '=', listInfo.topic])
                }
                if (listInfo.industry) {
                    filters.push(['and'], ['industry', '=', listInfo.industry])
                }
                const { items, last, total_pages } = await getProjectsApi({
                    page: listInfo.page,
                    size: listInfo.size,
                    filters: JSON.stringify(filters)
                })
                listInfo.finished = last || total_pages == 1
                projectList.value = [...projectList.value, ...items]
                listInfo.loading = false
            } catch (error) {
                showError(error)
            }
        }
        getProjects()

        const goProjectSurvey = (item: any) => {
            // if (item.status == ProjectStatus.End) {
            //     return
            // }
            root.$router.push({

                name: 'ProjectIntroduce',
                params: {
                    projectUuId: item.uuid
                }
            })
        }

        const deleteInfo = reactive({
            confirm: async (item: any, index: number) => {
                try {
                    await deleteProjectApi({
                        uuid: item.uuid
                    })
                    projectList.value.splice(index, 1)
                    root.$message.success(root.$t('deleteSuccess').toString())
                } catch (error) {
                    showError(error)
                }
            },
            cancel: () => { }
        })

        const copyInfo = reactive({
            confirm: async (item: any, index: number) => {
                try {
                    await cloneProjectApi({
                        uuid: item.uuid
                    })
                    await listInfo.init()
                    root.$message.success(root.$t('copySuccess').toString())
                } catch (error) {
                    showError(error)
                }
            },
            cancel: () => { }
        })

        const projectSharedDialogRef = ref()

        const openProjectSharedDialog = (uuid: string) => {
            projectSharedDialogRef.value.init(uuid)
        }

        interface Timezone {
            utc: string;
            id: string;
        }

        const utcMap = new Map<string, string>();
        const defaultTimezone = 'Asia/Shanghai'
        const defaultUTC = 'UTC+08:00'
        utcMap.set(defaultTimezone, defaultUTC)
        const getTimezones = async () => {
            try {
                const tz = await getTimezonesApi()
                tz.map((item: Timezone) => {
                    utcMap.set(item.id, item.utc)
                })
            } catch (error) {
                showError(error)
            }
        }

        getTimezones()

        const timeFormat = (date: string, tz: string) => {
            if (!date) {
                return ''
            }
            tz = currentTimezone()
            return `${root.$dateFormat(date)} (${utcMap.get(tz) || defaultUTC})`
        }

        const documentTitle = ref('KANTAR ART')

        onMounted(() => {
            documentTitle.value='KANTAR ART'
        })

        return {
            maskId,
            deleteInfo,
            copyInfo,
            listInfo,
            onProjectNameInput,
            projectList,
            ProjectStatus,
            goProjectSurvey,
            projectTopicOptions,
            projectIndustryOptions,
            projectSharedDialogRef,
            openProjectSharedDialog,
            timeFormat,
            documentTitle
        }
    }
})
